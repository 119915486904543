@font-face {
  font-family: findel;
  src: url("../../assets/Font/Findel-Display-Font/Demo_Fonts/Fontspring-DEMO-findel.otf");
}

@font-face {
  font-family: safira;
  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/info.txt");
}

@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
} 



/* Base styles for all screen sizes */
/* .home-main {
  height: auto;
  width: 100%;
  overflow: hidden;
}
.mob-heading {
  width: 100%;
  display: flex;
  justify-content: center;
}
.mobile-view-home {
  width: 100%;
  height: 100%;
}

.mobile-view-p h2 {
  font-size: 2.3vw;
  font-family: caudex;
  position: relative;
  z-index: 1;
  color: #fdfafa00;
  background: linear-gradient(180deg, rgb(255, 167, 45) 0%, rgb(255, 88, 22) 41%, rgb(224, 132, 2) 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

.mob-heading {
  font-size: 8vw;
  font-family: findel;
  z-index: 1;
  overflow: hidden;
  color: #fdfafa00;
  background: rgb(253, 187, 121);
  background: linear-gradient(180deg, rgb(255, 167, 45) 0%, rgb(255, 88, 22) 41%, rgb(224, 132, 2) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
}

.home-content {
  margin-bottom: -36rem;
  width: 100%;
  height: 100vh;
}

/* Media query for smaller screens (adjust values as needed) */
 /* @media screen and (max-width: 768px) {
  .mobile-view-p h2 {
    font-size: 4vw; /* Adjust font size for smaller screens */
  

  /* .mob-heading {
    font-size: 18vw; /* Adjust font size for smaller screens */
  

 @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap");

 :root {
  --bg: #000000;
  --clr-1: #ff8800;
  --clr-2: #ff7433;
  --clr-3: #f7cf72df;
  --clr-4: #e95708;

  --blur: 2rem;
  --fs: clamp(3rem, 8vw, 7rem);
  --ls: clamp(-1.75px, -0.25vw, -3.5px);
}

body{
  width: 100%;
  height: 100vh;
 
  background-size: cover;
position:relative;
  place-items: center;
  background-color: var(--bg);
  color: #fffefe;
  font-family: caudexBold;
} 

*,
*::before,
*::after {
  font-family: inherit;
  box-sizing: border-box;
}


.title-home {
  font-size: var(--fs);
  font-weight: 800;
  letter-spacing: var(--ls);
  position: relative;
  overflow: hidden;
  background: #00000000;
  margin: 0;
  font-family: findel;
  font-size: 15em;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 390px) {
  .title-home {
    font-size: 9em;
  }
}



.aurora {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  mix-blend-mode: darken;
  pointer-events: none;
}

.aurora__item {
  overflow: hidden;
  position: absolute;
  width: 60vw;
  height: 60vw;
  background-color: var(--clr-1);
  border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
  filter: blur(var(--blur));
  mix-blend-mode: overlay;
}

.aurora__item:nth-of-type(1) {
  top: -50%;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-1 12s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(2) {
  background-color: var(--clr-3);
  right: 0;
  top: 0;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-2 12s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(3) {
  background-color: var(--clr-2);
  left: 0;
  bottom: 0;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-3 8s ease-in-out infinite alternate;
}

.aurora__item:nth-of-type(4) {
  background-color: var(--clr-4);
  right: 0;
  bottom: -50%;
  animation: aurora-border 6s ease-in-out infinite,
    aurora-4 24s ease-in-out infinite alternate;
}

@keyframes aurora-1 {
  0% {
    top: 0;
    right: 0;
  }

  50% {
    top: 100%;
    right: 75%;
  }

  75% {
    top: 100%;
    right: 25%;
  }

  100% {
    top: 0;
    right: 0;
  }
}

@keyframes aurora-2 {
  0% {
    top: -50%;
    left: 0%;
  }

  60% {
    top: 100%;
    left: 75%;
  }

  85% {
    top: 100%;
    left: 25%;
  }

  100% {
    top: -50%;
    left: 0%;
  }
}

@keyframes aurora-3 {
  0% {
    bottom: 0;
    left: 0;
  }

  40% {
    bottom: 100%;
    left: 75%;
  }

  65% {
    bottom: 40%;
    left: 50%;
  }

  100% {
    bottom: 0;
    left: 0;
  }
}

@keyframes aurora-4 {
  0% {
    bottom: -50%;
    right: 0;
  }

  50% {
    bottom: 0%;
    right: 40%;
  }

  90% {
    bottom: 50%;
    right: 25%;
  }

  100% {
    bottom: -50%;
    right: 0;
  }
}

@keyframes aurora-border {
  0% {
    border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
  }

  25% {
    border-radius: 47% 29% 39% 49% / 61% 19% 66% 26%;
  }

  50% {
    border-radius: 57% 23% 47% 72% / 63% 17% 66% 33%;
  }

  75% {
    border-radius: 28% 49% 29% 100% / 93% 20% 64% 25%;
  }

  100% {
    border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
  }
}


 





.container-home {
  display: flex;
  padding: 30px;
  flex-direction: column;
  height: 90vh;
}

.text-rotator h1 {
  margin-bottom: 20px;
 
  

}

.description {
  line-height: 1.7rem;
  opacity: 0.6;

  @media screen and (min-width: 600px) {
      max-width: 50%;
  }
}



.text-rotator {
  position: relative;

  &,
  .text-rotator {
  color:rgb(255, 158, 65);
  }
  & .rotate-item {
      font-size: 30px;

      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
  }

  .rotate-home {
      margin-top: 10px;
      overflow: hidden;
      height:80px;
    


      .rotate-item {
          white-space: nowrap;
          display: block;
          animation: animaText 8s 20s ease infinite;
          color: #f2f0c9;
          font-size: 1.7em;
      }

      @media screen and (max-width: 600px) {
        height:55px;
        font-size: 0.7em;
           }
  }
}

@keyframes animaText {
  0%, 100% {
    opacity: 1;
  }

  5%, 95% {
    opacity: 1;
  }
}

@media screen and (max-width : 460px) {

  .title-home {
    font-size: 8em;
  }
  .container-home {
    height: auto;
  }
  .text-rotator
  .rotate-home
  .rotate-item { 

    font-size: 1.1em;
  }
}
@media screen and (max-width : 375px) {
.title-home{
  font-size: 7rem;
}
}


@media (max-width: 830px) {
  .container-home{
    height:50vh;
  }
  .text-rotator{
    color: #e95708;
  }
}