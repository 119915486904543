@font-face {
  font-family: safira;
  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}
@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
}
@font-face {
  font-family: caudexBold;
  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
}
/* Slideshow */
.airdrop-main {
    width: 100%;
    height: 100vh;
}
.airdrop-main {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.Airdrop-container {
  position: absolute;
  top: 50%;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  color: white;
  /* max-width: 80%; */
  align-items: center;
  justify-content: space-around;
}
.phara-01 {
    width: 100%;
}
.airdrop-header h1 {
  font-size: 2em;
  color: #f2f0c9;
  font-family: safira;
  width: 47vw;
}
.airdrop-explore-btn button {
  position: relative;
  right: 20px;
  bottom: 20px;
  border:none;
  box-shadow: none;
  width: 130px;
 
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.airdrop-explore-btn span {
background: linear-gradient(180deg, rgb(255 95 0) 0%, rgb(251 184 146 / 68%) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.airdrop-explore-btn span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.airdrop-explore-btn span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.airdrop-explore-btn:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.airdrop-explore-btn:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.phara-01 p {
  font-family: caudex;
  color: #fadb12;
  letter-spacing: 0.1rem;
  font-size: 18px;
  width: 40vw;
}
.left-slideshow {
  width: 436px;
  display: flex;
  height: 100%;
  gap: 2rem;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

}
/* .video-assets-02 video {
     z-index: 100;
} */

.slideshow {
  
    overflow: hidden;
    /* max-width: 500px; */
    width: 500px;
    border-radius: 40px;
    
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    border: 1px solid black;
    border-radius: 40px;
  }
  
  .slide {
    display: inline-block;
    background-size: cover;
    height: 400px;
    width: 100%;
    border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #cd9615;
  }

   
  .Typewriter__wrapper{
    font-size:2.4em;
    font-family: caudexBold;
     color: linear-gradient(90deg, rgba(45,96,231,1) 0%, rgba(169,81,52,1) 69%, rgba(242,154,49,1) 100%);
    font-weight: 1000;
    margin-top: 0rem;
   
  }
  .Typewriter__cursor {
      margin-left: 10px;
      font-size: 6px;
      color: transparent;
      border-left: 6px solid rgba(243, 132, 14, 0.568);
      position:relative;
    bottom:5px;
  }
  .gradient-text {
    background-image: linear-gradient(90deg, rgb(28, 85, 242) 0%, rgba(169, 81, 52, 1) 69%, rgba(242, 154, 49, 1) 100%);
    -webkit-background-clip: text; 
    font-family: caudexBold;
    background-clip: text;
    color: transparent; 
  }

@media screen and (max-width: 490px) {
  .phara-01{
    display: none;
  }
  .Airdrop-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .left-slideshow {
    width: 100%;
  }
  .Typewriter__wrapper{
    font-size: 1.3rem; 
  }
  .slideshow {
    width: 100%;
  }
  .airdrop-header h1 {
    font-size: 1em;
  }
}
@media screen and (max-width: 830px) {

  .phara-01 p{
    width: 65vh;
  }
  .Airdrop-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    top: 33rem;
    height: 95vh;
    
}
  
  .left-slideshow {
    width: 100%;
    gap: 1rem;
  }
  .Typewriter__wrapper{
    font-size: 2rem; 
    bottom: 20rem;
  }
  .gradient-text{
    position: relative;
    bottom: 0rem;
  }
  .slideshow {
    width: 100%;
   height: auto;
  }
  .airdrop-header h1 {
    font-size: 1.7rem;
    width: 52vh;
    bottom: 0rem;
    position: relative;
  }

  .airdrop-explore-btn button{
    left: 500px;
    bottom: 45px;
    color: #202020;
  }
}
