@font-face {
    font-family: caudex;
    src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
  }
  @font-face {
    font-family: safira;
    src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
  }
  @font-face {
    font-family: caudexBold;
    src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
  }
.wallets-main {
    /* background-image: url("../../assets/Images/iyxbg/10.png"); */
    background-size: cover;
    width: 100%;
    z-index: 1;
}
.Insights-header h3 {
    font-size: 1.5em;
    color: #f2f0c9;
    padding: 1rem;
    font-family: safira;
    margin-top: -5rem;
    display: flex;
    justify-content: center;
}
.wallets-header h3 {
    font-size: 2em;
    color:#f2f0c9;
    padding: 1rem;
    font-family: safira;
    font-size: 2em;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 830px) {
  .wallets-main{
position: relative;
    top: 55rem;
  }
}