@font-face {
  font-family: safira;
  src: url("../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}
@font-face {
  font-family: caudex;
  src: url("../assets/Font/Caudex/Caudex-BoldItalic.ttf");
}
.navbar {
  backdrop-filter: blur(2px) saturate(200%);
  -webkit-backdrop-filter: blur(0px) saturate(200%);
  background-color: rgba(239, 130, 14, 0.978);
  border-radius: 12px;
  border: 0px solid rgba(209, 213, 219, 0.3);
  margin: 0px;
  padding: 15px 0;
  width: 100%;
  height:10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
.list-items {
  text-decoration: none;
  color: #040404;
  text-align: center;
  font-family: caudex;
  font-size: 22px;
  font-style: normal;
  font-weight: 570;
}

.nav-container-002 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}
.signin-btn-nav {

  padding: 5px 20px;
  background-color: var(--buttonsignin);
  color: #fffefe;
  white-space: nowrap;
  text-align: center;
  font-family: caudexBold;
  font-size: 22.005px;
  border: none;
  font-weight: 500;

}



.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  gap: 16px;
  margin-right: 1rem;
  font-size: 2px;
}


.fa-code {
  margin-left: 1rem;
}


.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 79%;
    border-top: 1pxsolid #fcf9f9;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    
  background: linear-gradient( 180deg, #f1a05d 11%, rgba(217, 194, 18, 0.342) 50%, rgba(52, 57, 3, 0.21) 79% );

  backdrop-filter: blur(10px);
    left: 0px;
    width: 100%;
    height: 100vh;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .list-items {
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--buttonsignin);
  } 
   
}

/* hover Effect */

div.container-002 {
 margin: 0px;
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}


div.container-002 a {
  color: #000000;
  text-decoration: none;
  font-weight: 1000;
  padding: 10px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
/* Circle behind */
div.circleBehind a:before,
div.circleBehind a:after {
  position: absolute;
  top: 28px;
  left: 50%;
  width:30px;
  height: 30px;
  border: 1px solid #000000;
  transform: translateX(-50%) translateY(-50%) scale(0.8);
  border-radius: 50%;
  background: transparent;
  content: "";
  opacity: 0;
  transition: all 0.3s;
  z-index: -1;
}
div.circleBehind a:after {
  border-width: 1px;
  transition: all 0.4s;
}

div.circleBehind a:hover:before {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

div.circleBehind a:hover:after {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1.3);
}






