/* body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: #ffffe6; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.App {
  text-align: center;
}

.content-container {
width: 100%;
display: flex;
align-items: center;
height: 100vh;
justify-content: center;
}

.content-left {
  flex: 1;
  padding-right: 20px; 
  margin: 20px;
}



.typewrite {
  font-size: 41px;
  font-family: Georgia, serif;
  width: 46vw;
  height: 15vh;
  
}
.paragraph {
  width: 100%;

} */
* {
  margin: 0;
  padding: 0;
}








