@font-face {
    font-family: caudex;
    src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
  }
  
  @font-face {
    font-family: safira;
    src: url("../../assets/Font/safira-march-serif-font-1692863033-0/info.txt");
  }
  
  @font-face {
    font-family: caudexBold;
    src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
  }
  a {
    color: #f8f8f8d9;
  }

 
 .unorder-list p{
    margin: 0 0 2em;
    color: #f1ecec;
    font-family: caudex;

  }

  .unorder-list li{
    margin: 0 0 2em;
    color: #f1ecec;
    font-family: caudex;
  }

  .token-p{
    margin: 0 0 2em;
    color: #f1ecec;
    font-family: caudex;
  }
 
  
  .stick h2 {
    font-weight: 200;
    color: #ea4b06d7;
    font-family: caudexBold;
    font-size: 2em;
  }
 .page-header h1 {
    font-size: 3em;
    color: #f94b06e7;
    font-family: safira;
  }
  .unorder-list h4{
    font-size: 1.3em;
    margin: 0;
    font-family: caudexBold;
    color:rgb(252, 158, 17)
  }
  

  .float-main{
    background-color:black;
  }
  .canvas {
    max-width: 80em;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    padding: 0 2em 10em;
  }
  .page-header {
    padding: 2em 0 1em;
    margin: 0 0 4em;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .page-header h1 {
    margin: 0;
    padding: 0 0 0.2em;
    display: inline-block;
    font-size: 2em;
    padding: 78px 0 0.2em;
  }
  .page-header .summary {
    letter-spacing: 0.2em;
    color: #f1ecec;
  }
  article {
    margin: 0 0 6em;
    padding: 0 0 6em;
    box-sizing: border-box;
    position: relative;
  }
  article:after {
    content: '';
    display: block;
    position: absolute;
    width: 30em;
    left: 50%;
    font-size: 1em;
    border-bottom: 1px solid #ccc;
    margin-left: -15em;
    bottom: 0;
    z-index: 1;
  }
  article:before {
    content: '\021D5';
    display: block;
    position: absolute;
    text-align: center;
    color: #fefefe;
    font-size: 1.4em;
    top: 100%;
    left: 50%;
    width: 1.6em;
    height: 1.5em;
    padding: 0.1em 0 0;
    margin: -0.8em 0 0 -0.8em;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 2;
  }
  article:last-child:after,
  article:last-child:before {
    display: none;
  }
  @media screen and (min-width: 40em) {
    .sticky .stick {
      position: fixed;
      top: 0;
      transition: opacity 0.5s;
      opacity: 1;
      width: calc(45% - 5.8em);
    }
    .sticky.hide .stick {
      opacity: 0;
    }
    article {
      position: relative;
    }
    article {
      display: table;
    }
    article .content-article,
    article header {
      display: table-cell;
      vertical-align: top;
    }
    article .content-article h2,
    article header h2 {
      margin: 0 0 0.4em;
    }
    article .content-article {
      font-size: 1.2em;
    }
    article header {
      width: 45%;
      padding-right: 4em;
    }
    /* .float-main{
        position: absolute !important;
        top: 650px!important;
        width: 100%;
        height:580vh;
    } */
  }
  