:root {
  --scrollcolor: #fff;
  --scrollbackground: #e82313;
  }
  
   
  
  * {
    box-sizing: border-box;
  }
  
  .title {
    font-size: 2.5rem;
    font-family: system-ui;
    line-height: 1.1;
    font-weight: 300;
    color: #fff;
    margin: 4rem auto 1rem;
    width: 85%;
    max-width: 1280px;
  }
  
   
  
  .slider {
    width: 85%;
    max-width: 1280px;
    margin: 0 auto;
  }
  .slider__item img {
    background-size: cover;
  }
  
   
  
  .slider__content {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    display: flex;
    gap: 6rem;
    height: 32vh;
    padding-bottom: 1rem;
    margin-top: 11rem;
  
   
  
    &::-webkit-scrollbar {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 1rem;
      background: var(--scrollbackground);
    }
  
   
  
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: var(--scrollcolor);
    }
  
   
  
    &::-webkit-scrollbar-track {
      border-radius: 1rem;
      background: var(--scrollbackground);
    }
  }
  
   
  
  .slider__item {
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    width: 100%;
    border-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    aspect-ratio: 1;
  
   
  
    @media (min-width: 460px) {
      aspect-ratio: 2/3;
      min-width: calc((100% / 2) - 2rem);
    }
  
   
  
    @media (min-width: 940px) {
      min-width: calc((100% / 3) - 4rem);
    }
  }
  
   
  
  .slider__image {
    background-size: cover;
    display: block;
    width: 100%;
    height: auto;
  }
  
  .slider__info {
    position: relative;
    
    padding: 4rem 2rem 2rem;
    background: linear-gradient(to top, rgba(#000, 0.75), rgba(#203239, 0));
  
   
  
    h2 {
      color: #fff;
      font-family: system-ui;
      line-height: 1.1;
      font-weight: 300;
      font-size: 1.75rem;
      margin: 0;
    }
  }
  
   
  
  .slider__nav {
    margin: 1rem 0 4rem;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-content: center;
    align-items: center;
    pointer-events: none;
  
   
  
    @media (min-width: 460px) {
      justify-content: flex-end;
    }
  }
  
   
  
  .slider__nav__button {
    margin: 0;
    appearance: none;
    border: 0;
    border-radius: 2rem;
    background: #fff;
    color: #203239;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    line-height: 1;
    pointer-events: none;
    transition: 0.2s ease-out;
    opacity: 0.25;
  
   
  
    &--active {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 830px) {
  .slider__content {
    margin-top: 0rem;
  }
  .slider {
    max-width: 1000px;
    bottom:10rem
  }
}