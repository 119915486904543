@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
}


.preloader {

  height: 100vh;

  width: 100vw;

  background: rgba(0, 0, 0, 0.101);

  color: #fff;

  position: fixed;

  bottom: 0;

  left: 0;

  right: 0;

  z-index: 55;

  display: flex;

  justify-content: center;

  align-items: center;

  overflow: hidden;

  backdrop-filter: blur(5px);

}





.preloader .texts-container {

  display: flex;

  align-items: center;

  justify-content: space-between;

  height: 60px;

  width: 430px;
font-family: caudex;
  font-size: 20px;

  gap: 0.5rem;

  font-weight: 400;

  overflow: hidden;

  color: #fff;

}



.gradient-text-load {

  background: linear-gradient(rgb(248, 245, 241) 0%, rgb(243, 188, 167) 41%, rgb(243, 234, 135) 100%) ;

  background-clip: text !important ;

  color: transparent;

  -webkit-background-clip: text;

}