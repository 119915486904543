/* .home-content {
  position: absolute;
  z-index: 2;
  display: flex;
  gap: 0rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
/* .home-content h1{

  font-size: 1px;
  font-family: caudex;
  z-index: 1;
  overflow: hidden;
  color: #fdaa42;

} */
/*.home-content-p1 h3{
  font-size: 2em;
  font-family: caudex;
  z-index: 1;
  margin-bottom: 20rem;
  overflow: hidden;
  margin-left: -33rem;
  color: #fdaa42;
  width: 20vw;

}
.home-content-p2{
  font-size: 18px;
  font-family: caudex;
  z-index: 1;
  overflow: hidden;
  color: #fdaa42;
  margin-bottom: 33rem;
  width: 40vw;
  margin-left: -33rem;
 
}

.home-content h1{
  font-size: 15em;
  font-family: findel;
  z-index: 1;
  overflow: hidden;
  color: #fdfafa00;
  background: rgb(253, 187, 121);
  background: linear-gradient(90deg, rgb(255, 167, 45) 0%, rgb(255, 88, 22) 41%, rgb(224, 132, 2) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  margin-left: -4rem;
}
.home-content-main h1 {
  padding-inline-start: 5rem;
  margin-bottom: 26rem;
  font-size: 3.5rem;
  font-family: caudex;
  width: 40vw;
} 


/* fadinginup animation */

 /*@keyframes fadeInUp {
  from {
    transform: translate3d(0, 90px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}*/

/*@-webkit-keyframes fadeInUp {
 /* from {
    transform: translate3d(0, 80px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.home-content-main {
  animation-duration: 6s;
  animation-fill-mode: both;
  -webkit-animation-duration: 6s;
  -webkit-animation-fill-mode: both;
}
.home-content-main {
  opacity: 0;
}
.home-content-main {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
.home-content-p1 {
  animation-duration: 5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: both;
}
.home-content-p1 {
  opacity: 0;
}
.home-content-p1 {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}  */

.container-03{
    /* background-image: url("../../assets/Images/iyxbg/8.jpg"); */
    position: relative;
    background-size: cover;
}
#polaroids {
	position: relative;
	width: 105%;
	min-height: 515px;
	
	overflow: hidden;
}
.polaroid {
	position: absolute;
  padding: 0px 129px;
	width: 64%;
	margin: 0 19%;
}
.polaroid-image {
  max-width: 700px;
  margin: auto !important;
 
}
/* .polaroid img{
  width: 800px;
} */
.polaroid .img-responsive{
  width: 825px;
  display: flex;
  right: 5rem;
  position: absolute;

}
	#content-container-03
  .polaroid p {
		font-size: 21px !important;
	}
.polaroid:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border: 30px solid transparent;
	border-right: 0;
	margin-left: -43.5px;	
}
.arrow-previous {
  font-size: 50px;
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: 8%;
  z-index: 3;
  color: rgb(250, 4, 16);
}
.arrow-next {
  font-size: 50px;
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: 85%;
  z-index: 3;
  color: rgb(250, 4, 16);
}

.features-head h1{
  text-align:center;
  color: #f2f0c9;
  font-family: safira;
  width: 50vw;
  font-size: 2em;
  height: 15vh;
}


@media (min-width: 992px) and (max-width: 1200px) {
	#content-container .polaroid p {
		font-size:22px !important;
	}
	#polaroids {
		min-height:450px;
	}
}

@media (min-width: 768px) and (max-width: 980px) {
	#content-container .polaroid p {
		font-size:18px !important;
	}
	#polaroids {
		min-height:450px;
	}
}

@media (max-width: 767px) {
	#content-container .polaroid p {
		font-size:16px !important;
	}
	#polaroids {
		min-height:450px;
	}
  .arrow-previous {
    top: 20%;
  }
  .arrow-next {
    top: 20%;
  }
  
}
@media screen and (max-width:390px){
  .polaroid img  {
    width: 383px;
    /* display: flex; */
    right: -3rem;
    top: 10rem;
    position: absolute;
}
}
@media screen and (max-width:375px) {
	#content-container .polaroid p {
		font-size:14px !important;
	}
	.polaroid {
		padding: 0px 0px;
      width: 80%;
      margin: 0px 12%;
	#polaroids {
		min-height:450px;
    
    }
	}
 
  }

