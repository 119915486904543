
  

  
  .container-003 {
    position: relative;
    width: 65vmin;
    height: 65vmin;
    margin: 10vmin auto;
    overflow: hidden;
    perspective: 2000;
    transform-style: preserve-3d;
  }
  
  .ring {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-animation: a 8s infinite cubic-bezier(0.21, -1.21, 0.73, 2.1);
            animation: a 8s infinite cubic-bezier(0.21, -1.21, 0.73, 2.1);
  }
  .ring img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .ring:nth-child(n+1) {
    -webkit-clip-path: url(#clip1);
            clip-path: url(#clip1);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) -1%, #fff -1%);
    mask: url(#mask1);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  .ring:nth-child(n+2) {
    -webkit-clip-path: url(#clip2);
            clip-path: url(#clip2);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 11.5%, #fff 11.5%);
    mask: url(#mask2);
    -webkit-animation-delay: 0.1666666667s;
            animation-delay: 0.1666666667s;
  }
  .ring:nth-child(n+3) {
    -webkit-clip-path: url(#clip3);
            clip-path: url(#clip3);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 24%, #fff 24%);
    mask: url(#mask3);
    -webkit-animation-delay: 0.3333333333s;
            animation-delay: 0.3333333333s;
  }
  .ring:nth-child(n+4) {
    -webkit-clip-path: url(#clip4);
            clip-path: url(#clip4);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 36.5%, #fff 36.5%);
    mask: url(#mask4);
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  .ring:nth-child(n+5) {
    -webkit-clip-path: url(#clip5);
            clip-path: url(#clip5);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 49%, #fff 49%);
    mask: url(#mask5);
    -webkit-animation-delay: 0.6666666667s;
            animation-delay: 0.6666666667s;
  }
  .ring:nth-child(n+6) {
    -webkit-clip-path: url(#clip6);
            clip-path: url(#clip6);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 61.5%, #fff 61.5%);
    mask: url(#mask6);
    -webkit-animation-delay: 0.8333333333s;
            animation-delay: 0.8333333333s;
  }
  .ring:nth-child(n+7) {
    -webkit-clip-path: url(#clip7);
            clip-path: url(#clip7);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 74%, #fff 74%);
    mask: url(#mask7);
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .ring:nth-child(n+8) {
    -webkit-clip-path: url(#clip8);
            clip-path: url(#clip8);
    -webkit-mask: radial-gradient(closest-side, rgba(255, 255, 255, 0) 86.5%, #fff 86.5%);
    mask: url(#mask8);
    -webkit-animation-delay: 1.1666666667s;
            animation-delay: 1.1666666667s;
  }
  
  svg {
    width: 0;
    height: 0;
  }
  
  @-webkit-keyframes a {
    0% {
      transform: rotateY(0turn) rotateX(0turn) rotateZ(0turn);
    }
    25% {
      transform: rotateY(0.5turn) rotateX(0.5turn) rotateZ(0.5turn);
    }
    50% {
      transform: rotateY(0.5turn) rotateX(0.5turn) rotateZ(0.5turn);
    }
    75% {
      transform: rotateY(0turn) rotateX(0turn) rotateZ(0turn);
    }
    100% {
      transform: rotateY(0turn) rotateX(0turn) rotateZ(0turn);
    }
  }
  
  @keyframes a {
    0% {
      transform: rotateY(0turn) rotateX(0turn) rotateZ(0turn);
    }
    25% {
      transform: rotateY(0.5turn) rotateX(0.5turn) rotateZ(0.5turn);
    }
    50% {
      transform: rotateY(0.5turn) rotateX(0.5turn) rotateZ(0.5turn);
    }
    75% {
      transform: rotateY(0turn) rotateX(0turn) rotateZ(0turn);
    }
    100% {
      transform: rotateY(0turn) rotateX(0turn) rotateZ(0turn);
    }
  }
  /* Add -webkit prefix for Safari iOS support */
/* @-webkit-keyframes a {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes a {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */