@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
}
@font-face {
  font-family: safira;
  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}
@font-face {
  font-family: caudexBold;
  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
}
.contact-section {
  width: 100%;
  height: 100vh;
  background-color: #001923;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8rem;
}

.ball1 {
  content: "";
  position: absolute;
  width: 300px;
  height: 275px;
  background: linear-gradient(#ffeb3d82, #e91e63);
  border-radius: 50%;
  
  left: 0;
  margin-bottom: 25rem;
  z-index: 0;
}

.ball2 {
  content: "";
    position: absolute;
    width: 300px;
    height: 275px;
    background: linear-gradient(#2196f36e, #83d8ff);
    border-radius: 50%;
    right: 0;
    margin-top: 25rem;
    z-index: 0;
    margin-right: 37rem;
}

.contactus-container {
  z-index: 50;
    position: relative;
    width: 50%;
    max-width: 1050px;
    padding: 50px;
    background: rgb(252 255 255 / 15%);
    box-shadow: 0 25px 45px rgb(0 0 0 / 0%);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    overflow-x: hidden;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(4px);
    height: 89vh;
   
}


.contactus-container::before {
  content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 1%;
    z-index: -1;
    transform: skewX(64deg);;
}

.contactus-container h2 {
  text-align: center;
 font-family: safira;
  
  margin-bottom: 20px;
  font-size: 2em;
  color:#f2f0c9;
}

.contactus-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.contactus-col {
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin: 30px 0 20px;
}

.inputbox {
  position: relative;
  width: 100%;
  height: 50px;
  font-family: caudexBold;
  font-size: 1.2rem;
  color: #000000;
  font-weight: bold;
}

.inputbox input,
.inputbox textarea {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 15px;
  padding: 0 10px;
  z-index: 1;
  resize: none;
}

.contactus-text {
  position: absolute;
  top: -8px;
  left: -1px;
  line-height: 0px;
  padding: -1px 0px;
  transition: .3s;
  color: #ffffff;
}

.inputbox input:focus + .contactus-text,
.inputbox textarea:focus + .contactus-text {
  top: -35px;
  left: -10px;
}


.contactus-line {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: 0.3s;

}

.inputbox input:focus ~ .contactus-line,
.inputbox textarea:focus ~ .contactus-line {
  height: 100%;
}



.textarea {
  position: relative;
  width: 100%;
  height: 100px;
}

.textarea .contactus-text {
  padding: 0 10px;
}

.textarea textarea {
  padding: 10px;
}

.contactus-col input[type="SUBMIT"] {
  background-color: rgb(52, 199, 248);
  padding: 0.5rem 1rem;
  font-family: caudexBold;
  font-size: 1.3em;
  outline: none;
  border-radius: 37px 7px;
  font-weight: bolder;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .contactus-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 630px) {
  .contactus-container {
    max-width: 100%;
  }
  .contact-section {
    width: 100%;
    height: 100%;
    background-color: #001923;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contactus-container {
       margin: 0;
       width: 100%;
       height: 100vh;
  }
}

@media screen and (max-width: 400px) {
  .contactus-container {
    padding: 50px 20px;
  }

  .contact-section:after {
    content: "";
    position: absolute;
    width: 250px;
    height: 250px;
    background: linear-gradient(#2598f5, #83d8ff);
    border-radius: 50%;
    /* bottom: -400px; */
    right: 0;
  }
}
.contact-content{
  display :flex ;
  justify-content: space-between;
  font-family: caudex;
  font-size: 18px;
  color: antiquewhite;
}