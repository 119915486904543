@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
}
@font-face {
  font-family: caudexBold;
  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
}
.about-container {
    width: 100%;
    height: auto; 
    margin: 50px auto; 
  }
   
  .nav {  
    width: 100%;  
    margin: 50px 0; 
  }
  
   
  
  .nav ul {
    display: flex;
    width: 100%;
    height: 20vh;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
  }
  
   
  
  .nav ul li {
  
      list-style: none;
  
  }
  
   
  
  .nav li.nav-item {
  
    width: 200px;
  
    height: 40px;
  
    line-height: 40px;
  
   
  
  }
  
   
  
  .nav li.nav-item:not(:last-child) {
  
    margin-right: 15px;
  
  }
  
   
  
  .nav li.nav-item a {
    display: block;
    border-radius: 35px 5px;
    border: 1px solid #ffc9c5;
    text-align: center;
    background: #00000088;
    color: #fadb12;
    font-family: caudexBold;
    text-decoration: none;
    transition: background 0.1s linear;
    cursor: pointer;
  
  }
  
   
  
  .nav li.nav-item a:hover {
  
    background: #000000;
  
  }
  
   
  
  .nav li.nav-item.active a {
  
    background: #000000;
  
  }
  
   
  
  .content {
       height: auto;;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  
  }
  
   
  
  .content-area {
  
    display: none;
   
    align-items: center;
    top: 0;
    font-family: caudex;
    width: 72%;
    border-radius: 35px 8px;
   
    color: #fadb12;
    background: #ffffff4c;
    font-size: 22px;
    padding: 40px;
    box-sizing: border-box;
  
  }
  
   
  
  .content-area.active {
  
    display: block;
  
  }
  @media (max-width: 830px) { 

    .content-area {
      width: 50vh;
      font-size: 18px;
      height: auto;
  }
  .about-container {
    display: none;
   
  }
    }
    .nav ul {
      display: flex;
      gap: 1rem; 
      height: auto;
    }
   
  
  /* @media (max-width: 397px) { 
    .about-container{
      top:-6rem;
      position: relative;
    }
    .button.contract-btn{
      top: -3rem;
    }
  } */