@font-face {
    font-family: caudex;
    src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
  }
  @font-face {
    font-family: safira;
    src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf")
  }
  @font-face {
    font-family: caudexBold;
    src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
  }


  .buy-main {
    width: 100%;
    height: 115vh;
    /* background-image: url("../../assets/Images/iyxbg/9.png"); */
    background-size: cover;
}

   

.buy-main-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 7rem;
    padding: 2rem;
    justify-content: space-around;
}
.rightside-buy-content {
    width: 43%;
    margin-top: 6rem;
}
.leftside-buy-content {
    width: 50%;
}
.card-001 {
    border: 1px solid white;
    display: flex;
    color: #fadb12;
    width: 100%;
    gap: 19px;
    align-items: center;
    font-family: caudex;
    font-size: 18px;
}
.card-no-001 {
    border: 1px solid darkseagreen;
    color: black;
    background-color: #f6f6f670;
    padding: 2rem;
}
.card-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.scanner img {
    position: absolute;
    width: 10vw;
}

.exchange img {
    width: 100%;
    margin-top: 1rem;
    border-radius: 2px;
   
    height: 50%;
}
.card-001 {
    backdrop-filter: blur(200px) saturate(150%);
    background-color: rgba(255, 255, 255, 0.248);
}
.leftside-content-header h1{
    color: #f2f0c9;
    font-family: safira;
    width: 50vw;
    font-size: 2em;
  
    height: 15vh;
}

.leftside-content-p p{
    color: #fadb12;
    font-family: caudexBold;
    font-size: 18px;
    width: 41vw;
    height: 7vh;
   
  
}

.scanner-content p {
    color: rgb(240,232,119);
    font-family: caudex;
    position: relative;
    top: 13rem;
    left: 6rem;
    width: 100%;
}
.scanner-main {
    display: flex;
    gap: 5rem;
    width: 65%;
    justify-content: center;
    align-items: center;
    margin-inline-start: 14rem;
    flex-wrap: nowrap;
    flex-direction: row;
}
.buy-button-01 button {
    position: relative;
    right: 100px;
    bottom: 20px;
    border:none;
    box-shadow: none;
    width: 200px;
   
    line-height: 42px;
    -webkit-perspective: 230px;
    perspective: 230px;
  }
  .buy-button-01 span {
  background: linear-gradient(180deg, rgb(255 95 0) 0%, rgb(251 184 146 / 68%) 100%);
    display: block;
    position: absolute;
    width: 200px;
    height: 40px;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    border-radius: 5px;
    margin:0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .buy-button-01 span:nth-child(1) {
    box-shadow:
     -7px -7px 20px 0px #fff9,
     -4px -4px 5px 0px #fff9,
     7px 7px 20px 0px #0002,
     4px 4px 5px 0px #0001;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
  }
  .buy-button-01 span:nth-child(2) {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
  }
  .buy-button-01:hover span:nth-child(1) {
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .buy-button-01:hover span:nth-child(2) {
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
   color: transparent;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.card-main-header{
    font-size: 1.3em;
    font-family: caudexBold;
    
    color: #fadb12;
}


.buy-button-01 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  

}

/* Media query for tablet view */
@media (max-width: 830px) {
    .buy-main {
        height: 80vh; 
    }
    .buy-main-container {
        flex-direction: column;
        gap: 2rem;
        padding: 1rem;
        height: auto; 
    }

    .leftside-buy-content,
    .rightside-buy-content {
        width: 100%;
    }
.card-main-header {
    bottom: 3rem;
    position: relative;
}
    .card-001 {
        font-size: 18px;
       
    }
    .leftside-content-header h1 {
        margin: 0;
        height: 6vh;
        width: 100%;
        font-size: 2em;
    }
    .scanner-content {
        padding: 1rem;
    }
    .leftside-content-p {
        padding: 1rem;
    }
    .leftside-content-p p {
        color: rgb(240,232,119);
    font-family: caudexBold;
    font-size: 1.3em;
    width: 100%;
    margin: 0;
    height: auto;
}
.scanner img {
    height: 15vh;
    display: none;
    width: 10vw;
}

.scanner-content p {
    color: rgb(240,232,119);
    font-family: caudex;
    margin: 0;
    width: 44vh;
    display: none;
    font-size: 1.3em;
}
.buy-button-01 {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
    padding: 1rem;
}
.buy-button-01 button {
    margin: 0;
    right: 15px;
    bottom: 28px;
}
    }
    .exchange img {
        width: 100%;
        margin-top: 1rem;
        border-radius: 2px;
       
        margin: 0;
        height: 50%;
    }
    

.scanner-main {
    display: flex;
    /* gap: 5rem; */
    gap: 0;
    width: 65%;
    justify-content: center;
    align-items: center;
   
    margin: 0;
    flex-wrap: nowrap;
    flex-direction: column;
}


   
