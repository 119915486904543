

.svg-path {

    

    stroke: #e4ff18;

  }

 

  @media (max-width: 48em) {

   

    .svg-path {

      stroke: #e4ff18;

    }

  }