@font-face {

    font-family: caudex;

    src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");

  }

  @font-face {

    font-family: caudexBold;

    src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");

  }

 

 

.footer-main {

    display: block;

    width: 100%;

    height: 100vh;

    border-radius: inherit;

    background-color: #000000;

}

 

.footer-main img{

   

    display: block;

    width: 100%;

    height: auto;

    position: absolute;

    border-radius: inherit;

    object-position: center center;

    object-fit: cover;

    image-rendering: auto;

   

}

 

.footer-content {

 

    height:50vh;

position: relative;

    display: flex;

align-content: center;

justify-content: space-around;

align-items: flex-end;

    position: relative;

}

 


 

.social-platform {

    color: #fffcfc;

    display: flex;

    gap: 1rem;

    flex-direction: column;

    align-items: center;

    font-family: caudexBold;

}

ul.support-list {

    display: flex;

    gap: 1.5rem;

    flex-direction: column;

    align-items: flex-start;

}

.footer-icons {

    display: flex;

    flex-direction: row;

    align-items: center;

    gap: 1rem;

}

.fce-icon {

    border: 1px solid rgb(216, 214, 214);

    display: flex;

    padding: 2px;

    opacity: 0.723;

    justify-content: center;

    align-items: center;

}

.tele-icon {

    border: 1px solid rgb(216, 214, 214);

    opacity: 0.723;

    display: flex;

    padding: 2px;

    justify-content: center;

    align-items: center;

}

.insta-icon {

    border: 1px solid rgb(216, 214, 214);

    display: flex;

    opacity: 0.723;

    padding: 2px;

    justify-content: center;

    align-items: center;

}

.twi-icon {

    border: 1px solid rgb(216, 214, 214);

    display: flex;

    opacity: 0.723;

    padding: 2px;

    justify-content: center;

    align-items: center;

}

.link-icon {

    border: 1px solid rgb(216, 214, 214);

    display: flex;

    opacity: 0.723;

    padding: 2px;

    justify-content: center;

    align-items: center;

}

.you-icon {

    border: 1px solid rgb(216, 214, 214);

    display: flex;

    padding: 2px;

    opacity: 0.723;

    justify-content: center;

    align-items: center;

}

a {

    text-decoration: none;

    color: rgb(255, 255, 255);

}

.footer-right{
    display: flex;
    gap: 5rem;
    font-family: caudexBold;
   
    
}

.support-list li {

   list-style: none;

   color: rgb(255, 254, 254);

   opacity: 0.723;

   font-family: caudex;

}

.support-list h4 {

    color: rgb(230, 229, 229);

}
.support h2 {
    top: -2rem;
    position: relative;

    color: rgb(255, 255, 255);

}
 

   

.bottom-content h4 {

    width: 100%;

    display: flex;

    justify-content: center;

    color: rgb(255 255 255);

    font-family: caudexBold;
    margin-top: 20rem;

}

@media screen and (max-width : 479px) {
    .footer-content {
     flex-direction: column;
    justify-content: center;
    gap: 4rem;
    }
    .social-platform{
        bottom: -30rem;
        left:-5rem;
        position: relative;
    }
    .footer-right{
        font-size: 15px;
        position: relative;
        left:-1rem;
    }
    }

    @media screen and (max-width : 830px) {
        .footer-main{
            position: relative;
            top: 35rem;
        }
        .footer-content{
            height: 10vh;
        }
        .footer-main img{
            position: relative;
            top: 37rem;
        }
        .footer-right{
            position: relative;
            top: 10rem;
        }
        .bottom-content h4{
            margin-top: 28rem;
        }
        }

    