@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
}
@font-face {
  font-family: safira;
  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}

.tokenomics-main {
    /* background-image: url("../../assets/Images/iyxbg/13.png"); */
    height: 117vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.token-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.token-btn button {
  position: relative;
  left: 190px;
  bottom: 0px;
  border:none;
  box-shadow: none;
  width: 130px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.token-btn span {
background: linear-gradient(180deg, rgb(255 95 0) 0%, rgb(251 184 146 / 68%) 100%);
  display: block;
  position: absolute;
  color:#000000;
  width: 130px;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.token-btn span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.token-btn span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.token-btn:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.token-btn:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);

}

.tokenomics-btn button{
  position: relative;
  left: 190px;
  bottom: 0px;
  border:none;
  box-shadow: none;
  width: 130px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.tokenomics-btn span {
background: linear-gradient(180deg, rgb(255 95 0) 0%, rgb(251 184 146 / 68%) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  color:#000000;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.tokenomics-btn span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.tokenomics-btn span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.tokenomics-btn:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.tokenomics-btn:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.left-side-tokenomics img {
  height: auto;
    margin-left: 0px;
    margin-top: 150px;
    width: 100%;
}
.token-header h1 {
      color:coral;
      font-size: 3em;
      font-family: caudex;
      
}

  
  .card {
    width: 280px;
    display: block;
    margin: 60px auto;
    box-shadow: 10px 5px 40px 20px #2d1896;
    transition: 0.25s;
  }
.card img {
    width: 20px;
    height: 20vh;
}
   .card:hover {
    box-shadow: 10px 5px 40px 20px #271580;
    cursor: pointer;
  }
  .card:hover .button-primary {
    transform: translate(10px, 0);
  }
  
  .card-header {
    height: 150px;
    width: 100%;
    padding: 15px;
    width: 100%;
    background-size: cover;
    color: #fff;
  }
  
  .card-header--title {
    text-transform: uppercase;
    margin: 0;
  }

  .token-header h1 {
    font-family: safira;
    color:  #f2f0c9;
    /* margin-top: 6rem; */
    font-size: 2em;
  }

  .token-content-p {
    width: 85vw;
   
    font-size: 18px;
    margin: auto;
}
.token-content-p p {
  width: 100%;
    color: #fadb12;
    letter-spacing: 0.1rem;
    font-family: caudex;
    text-transform: capitalize;
    margin-top: auto;
    font-size: 18px;
}
.tokenomics-btn {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  justify-content: center;
  color:#000000;
}
  
  .card-body {
    padding: 15px;
    background-color: #fff;
    width: 100%;
    background-image: auto;
  }
  
  .date {
    font-size: 11px;
    font-weight: 600;
    color: grey;
  }
  
  .body-content {
    padding-bottom: 40px;
    font-size: 13px;
    line-height: 1.8;
  }
  
  .button-primary {
    border: none;
    box-shadow: none;
    font-family: inherit;
    background-color: transparent;
    color: #000000;
    font-size: 15px;
    transition: 0.25s;
  }
  .button-primary i {
    padding-right: 4px;
  }
  
  .designer-link {
    color: #fff;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
    display: block;
  }

  
  .app-header {
    display: block;
    padding: 30px;
  }
  
  .app-title {
    display: inline-block;
    width: 100%;
    margin: 80px 0 0 0;
    float: left;
    position: relative;
    color: #fff;
  }

  .app-title h1 {
    font-size: 52px;
    margin: 0;
  }
  .app-title p {
    font-weight: 200;
    opacity: 0.5;
  }
  
  .app-title-content {
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  .left-side-tokenomics {
    width: 100%;
}
.right-side-tokenomics {
  width: 100%;
  margin: auto;
}
  
  .tokenomic-container {
    height: 57vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
  
  .app-card-list {
    display: inline-block;
    width: 100%;
    float: right;
    margin: 0;
    white-space: normal;
    overflow-x: scroll;
    margin-top: -70px;
  }
  .app-card-list::-webkit-scrollbar {
    display: none;
  }
.token-header{
  margin: auto;
    width: fit-content;
}
@media screen and (max-width: 490px) {
  .tokenomics-main {
    height: auto;
  }
  .tokenomic-container {
    display: flex;
    height: auto;
    flex-direction: column;
  }
  .left-side-tokenomics img {
    width: 100%;
    height: 31vh;
    margin: 0;
  }
  .token-content-p p {
    width: 100%;
    font-size: 6vw;
    padding: 1rem;
  }
  .tokenomics-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}
.tokenomics-btn button {
  margin: 0;
}
.token-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.token-header h1 {
  font-size: 1em;
}
.left-side-tokenomics {
  margin-top: 2rem;
}
  
}
@media screen and (max-width: 830px) {
  .token-header h1{
    margin-top: 0rem;
    font-size: 1.7rem;
  }
  .left-side-tokenomics img {
    margin-top: 50px;
  }
  .token-btn button{
    left: 0px;
    top: 50px;
  }
  .tokenomics-btn button{
    left:0px;
    color:#000000
  }
.tokenomics-main{
  height: auto;
}
}
  