@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
}

@font-face {
  font-family: safira;
  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}

.about-main {
  width: 100%;
  height: auto;
  /* background-image: url("../../assets/Images/iyxbg/3.jpg"); */
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.contract-btn {
  position: relative;
  right: 20px;
  bottom: 20px;
  border:none;
  box-shadow: none;
  width: 130px;
 
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.contract-btn span {
  /* background: rgb(0,172,238); */
background: linear-gradient(180deg, rgb(255 95 0) 0%, rgb(251 184 146 / 68%) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  text-align: center;
  color: #202020;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.contract-btn span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.contract-btn span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.contract-btn:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.contract-btn:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}


.pulse-image {
  animation: pulse 2s infinite;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(239, 206, 98, 0.4);
  }

  5% {
    transform: scale(1.01);
  }

  15% {
    transform: scale(1);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(168, 63, 57, 0);
  }

  100% {
    box-shadow: 0 0 0 100px rgba(168, 63, 57, 0);
  }
}

.about-content-002 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: auto;
  align-items: center;
}

.about-container02 {
  width: 95%;
  height: auto;
  backdrop-filter: blur(3px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.248);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;
}

h1.aboutus-heading-01 {
  font-size: 2em;
  font-family: safira;
  color: #f2f0c9;
  -webkit-background-clip: text;
  background-clip: text;

}

.left-side-02-phara {
  width: 100%;
  font-size: 18px;
  color: #fadb12;
  letter-spacing: 0.1rem;
  font-family: caudex;
}

.left-side-02 {
  padding: 2rem;
}


  /* Mobile view */
  @media (max-width: 830px) {
    .about-main {
      height: 120vh;
      display: flex;
      align-items: baseline;
      padding: 2rem 0;
      
    }

    .about-content-002 {
      height: auto;
      flex-direction: column;
      text-align: center;
      gap: 5rem;
    }
    
    button.contract-btn {
      margin: 0;
      bottom: 230px;
    }
    .contract-btn span {
      color: #202020;
    }

    .about-container02 {
      display: flex;
      justify-content: flex-start;
      height: 143vh;
    }
  

    .right-side-01 img {
      display: none;
    }

    .content-area {
      font-size: 16px;
    }

    h1.aboutus-heading-01 {
      font-size: 2em;
      
    }

    .left-side-02-phara {
      font-size: 18px;
      
    }

    .left-side-02 {
      padding: 0;
    }
  }

  @media (max-width: 480px) {
    .right-side-01 img {
      width: 200px;
   
      height: 242px;
    }

    .left-side-02 {
      padding: 0;
    }
  }
