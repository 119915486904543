@font-face {

  font-family: caudex;

  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");

}

@font-face {

  font-family: safira;

  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");

}

@font-face {

  font-family: caudexBold;

  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");

}

 

.developer-container {

  align-items: center;

  background-image: url("../../assets/Images/iyxbg/12.png");

  background-position: center;

  background-size: 100% 100%;

  display: flex;

  font-family: caudexBold;

  font-size: 2em;

  flex-wrap: wrap;

  justify-content: center;

  height: auto;

  width: 100%;

  margin: 0;

}

 

.team-headers {

  width: 100%;

  display: flex;

  font-family: safira;

  font-size: 3rem;

  color: #f2f0c9;

  height: auto;

  align-items: flex-end;

  justify-content: center;

}

 

.person {

  align-items: center;

 

  display: flex;

 

  flex-direction: column;

 

  width: 280px;

}

 

.teams-container {

  border-radius: 50%;

 

  height: 312px;

 

  -webkit-tap-highlight-color: transparent;

 

  transform: scale(0.48);

 

  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);

 

  width: 400px;

}

 

.teams-container:after {

  /* background-color: #f2f2f2; */

 

  content: "";

 

  height: 10px;

 

  position: absolute;

 

  top: 390px;

 

  width: 100%;

}

 

.teams-container:hover {

  transform: scale(0.54);

}

 

.container-inner {

  clip-path: path(

    "M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z"

  );

 

  position: relative;

 

  transform-origin: 50%;

 

  top: -200px;

}

 

.circle {

  background-color: #fee7d3;

 

  border-radius: 50%;

 

  cursor: pointer;

 

  height: 380px;

 

  left: 10px;

 

  pointer-events: none;

 

  position: absolute;

 

  top: 210px;

 

  width: 380px;

}

 

.img {

  pointer-events: none;

 

  position: relative;

 

  transform: translateY(20px) scale(1.15);

 

  transform-origin: 50% bottom;

 

  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

}

 

.teams-container:hover .img {

  transform: translateY(0) scale(1.2);

}

 

.img1 {

  left: 23px;

  top: 195px;

  width: 400px;

}

 

.img2 {

  left: -46px;

 

  top: 174px;

 

  width: 444px;

}

 

.img3 {

  left: 0px;
  top: 152px;
  width: 407px;
  height: 445px;
}
 



.img4 {

  left: 11px;

  top: 149px;

  width: 400px;

  height: 62vh;

}

.img5 {

 

  left: 22px;

 

  top: 102px;

 

  width: 340px;

 

}

 

.divider {

  background-color: #f1d3d3;

 

  height: 1px;

 

  width: 160px;

}

 

.name {

  color: #f9f7f6;

  font-size: 30px;

font-family: caudexBold;

  font-weight: 600;

 

  margin-top: 16px;

 

  text-align: center;

}

 

.team-title {

  color: #fbea03;

 

  font-family: arial;

font-family: caudex;

  font-size: 18px;

 

  font-style: italic;

 

  margin-top: 4px;

}