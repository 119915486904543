
@font-face {
  font-family: caudex;
  src: url("../../assets/Font/Caudex/Caudex-Regular.ttf");
}
@font-face {
  font-family: safira;
  src: url("../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}
@font-face {
  font-family: caudexBold;
  src: url("../../assets/Font/Caudex/Caudex-Bold.ttf");
}

  .main-applications {
    width: 100%;
  
    height: auto;
  /* background-image: url("../../assets/Images/iyxbg/10.png"); */
   background-size: cover;
   background-position: 100% 100%;
  }
  
  .main-applications  .app-headers {
    width: 100%;
    display: flex;
    font-family: safira;
    font-size: 2em;
    height: 10vh;
    color: #f2f0c9;
   
    justify-content: center;
   
}
  
.application-content {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
   
  
.application-content  .card001 {
  
    width: calc(28.33% - 20px);
  
    
  
    height: 250px;
  
    background-color: #fffefe9c;
  
    margin: 10px;
  
    border-radius: 25px;
  
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  
    border: 2px solid rgba(7, 7, 7, 0.156);
  font-family: caudex;
    font-size: 18px;
  
    transition: all 0.3s ease;
  
    position: relative;
  
    display: flex;
  
    justify-content: center;
  
    align-items: center;
  
    flex-direction: column;
  
   
  
    padding: 20px;
  
    box-sizing: border-box;
  
  }
  
   
  
  .application-content  .card001  .icon001 {
  
    margin: 0 auto;
  
    width: 80px;
  
    height: 80px;
  
  
  
    background: linear-gradient(
  
      90deg,
  
      #ff7e7e 0%,
  
      #ff4848 40%,
  
      rgba(0, 0, 0, 0.28) 60%
  
    );
  
    border-radius: 100%;
  
    display: flex;
  
    justify-content: center;
  
    align-items: center;
  
    color: rgb(0 0 0);
    
    transition: all 0.8s ease;
  
    background-position: 0px;
  
    background-size: 200px;
  
  }
  
   
  
  .application-content  .card001  .icon001 i {
  
    font-size: 36px; 
  
    display: flex;
  
    justify-content: center;
  
    align-items: center;
  
    height: 100%;
  
  }
  
   
  
  .application-content .card001 .title001 {
  
    width: 100%;
  
    margin: 0;
  
    text-align: center;
  
    margin-top: 15px;
  
    color: #202020;
  
    font-family: caudexBold;
    font-size: 1em;
  
    text-transform: uppercase;
  
    letter-spacing: 2px;
  
    
  
    overflow: hidden;
  
    white-space: nowrap;
  
    text-overflow: ellipsis;
  
  }
  
   
  
  .application-content .card001 .text001 {
  
    width: 80%;
  
    margin: 0 auto;
  
    font-size: 13px;
  
    text-align: center;
  
    margin-top: 20px;
  
    color: white;
  
    font-weight: 200;
  
    letter-spacing: 2px;
  
    opacity: 0;
  
    max-height: 0;
  
    transition: all 0.3s ease;
  
  }
  
   
  
  .application-content    .card001:hover {
  
    height: 270px;
  
  }
  
   
  
  .application-content    .card001:hover .text001 {
  
    opacity: 1;
  
    max-height: 40px;
  
  }
  
   
  
  .application-content    .card001:hover .icon001 {
  
    background-position: -120px;
  
  }
  
   
  
  .application-content    .card001:hover .icon001 i {
  
    background: linear-gradient(90deg, #ff7e7e, #ff4848);
  
    -webkit-background-clip: text;
  
    -webkit-text-fill-color: transparent;
  
    opacity: 1;
  
   
  
  }
  /* tab view */
  @media screen and (max-width: 830px) {
    .main-applications{
      position: relative;
      top: 50rem;
      height: auto;
    }
    .application-content {
        padding-top: 0px; 
        
    }
    .main-applications  .app-headers {
      height: auto;
      padding: 2rem;
      font-size: 2em;
    }

    .application-content  .card001 {
      width: calc(32.33% - 20px);
      height: 250px;
    }

    .application-content .card001 .icon001 {
        width: 60px; 
        height: 60px;
        background-size: 120px; 
    }
}

/* Styles for mobile */
@media screen and (max-width: 480px) {
    .application-content {
      gap: 1rem; 
      padding: 8px;
    }
    .main-applications {
      height: auto;
    }
    .app-headers {
      height: auto;
      padding: 1rem;
      font-size: 1.2em;
    }

    .card001 {
        width: 100%; 
        height: 180px; 
        margin: 0;
    }

    .icon001 {
        width: 50px; 
        height: 50px;
        background-size: 100px; 
    }

    .card001 .title001 {
        font-size: 0.9em; 
    }

    .card001 .text001 {
        font-size: 12px; 
    }
}

