@font-face {
  font-family: caudex;
  src: url("../../../assets/Font/Caudex/Caudex-BoldItalic.ttf");
}
@font-face {
  font-family: safira;
  src: url("../../../assets/Font/safira-march-serif-font-1692863033-0/safiramarchpersonaluse-gxeky.otf");
}
@font-face {
  font-family: caudexBold;
  src: url("../../../assets/Font/Caudex/Caudex-Bold.ttf")
}
@font-face {
  font-family: caudexItallic;
  src: url("../../../assets/Font/Caudex/Caudex-Italic.ttf")
}


:root {
  --body-color: #2c2d2a;
    --savanna-bg: #d58f6ecf;
    --beach-bg: #164b2ec2;
    --glacier-bg: #6dd3f1d1;
   
    --arrow-fill: #333231;
    --body-font: "Montserrat", sans-serif;
    --italic-font: "EB Garamond", serif;
}

* {
  box-sizing: border-box;
  outline: none;
}
.explore-main {
   /* background-image: url("../../../assets/Images/iyxbg/2.png"); */
   background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.explore-back-btn {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px 40px;
  background-color: #00000000;
  color: black;
  width: 100px;
}


.img {
    max-width: 100%;
}

.container-btn{
  position: relative;
  font-family: caudexBold;
  font-size: 1.3em;
 margin-top: 2rem;
  border-radius: 36px 6px;
  padding: 0.5rem 2rem;
  color: rgb(252, 239, 239);
  cursor: pointer;
 
}



.container {
    max-width: 1100px;
    border-radius: 4px;
    max-height: 680px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    background-color: #f5abfbc7;;
    padding: 0 30px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}
@media (max-width: 480px) {
  .container {
    height: 100%;
    max-height: 100%;
  }
}

.logo {
  width: 116px;
}

.header {
  display: flex;
  align-items: center;
  height: 62px;
  width: 100%;
  white-space: nowrap;
  flex-shrink: 0;
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid rgba(44, 45, 42, 0.25);
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--beach-bg);
  z-index: 6;
}
@media (max-width: 575px) {
  .header {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}
.header-menu {
  display: flex;
  align-items: center;
  margin-left: auto;
}
@media screen and (max-width: 740px) {
  .header-menu {
    display: none;
  }
}
.header-menu a:not(:first-child) {
  margin-left: 30px;
}
.header .menu-icon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.header .menu-icon svg {
  width: 22px;
}
.header-icons {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.header-icons svg {
  width: 18px;
}
.header-icons svg:not(:last-child) {
  margin-right: 20px;
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 320px;
}
@media screen and (max-width: 930px) {
  .left-side {
    text-align: center;
    max-width: 450px;
  }
}

.mySwiper {
  display: flex;
  flex-grow: 1;
  position: relative;
 
}

.swiper-slide {
  padding: 42px 0 30px;
  display: flex;
  flex-grow: 1;
  position: relative;
  justify-content: space-around;
  
}
@media screen and (max-width: 930px) {
  .main {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}
.main-header {
  text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 4px;
    font-weight: 600;
    transition-delay: 0.2s;
    font-family: 'caudexBold';
    color: black
}
.main-title {
  font-family: 'safira';
  font-size: 3rem;
  font-weight: 400;
  margin-top: 10px;
  line-height: 2em;
  transition-delay: 0.3s;
  color: black
}

.main-content__title {
  font-size: 18px;
  font-family: 'caudex';
  font-style: italic;
  margin-bottom: 14px;
  transition-delay: 0.2s;
  color: black

}
.main-content__subtitle {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 24px;
  letter-spacing: -0.01em;
  transition-delay: 0.3s;
  font-family: 'caudexItallic';
  color: black
}
/* .main-content .more-menu {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition-delay: 0.4s;
} */
@media screen and (max-width: 930px) {
  .main-content .more-menu {
    justify-content: center;
  }
}
.main-content .more-menu svg {
  width: 28px;
  height: 18px;
  margin-left: 10px;
}

.center {
  display: flex;
  margin-left: 120px;
  position: relative;
  flex-shrink: 0;
}
@media screen and (max-width: 930px) {
  .center {
    margin-left: 0;
    margin-bottom: 56px;
  }
}
.center .bottle-bg {
  width: 490px;
    height: 489px;
    object-fit: cover;
    border-radius: 160px;
}
@media screen and (max-width: 930px) {
  .center .bottle-bg {
    width: 260px;
    height: 390px;
    
  }
  .swiper-slide {
    padding: 42px 0 30px;
    display: flex;
  
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 575px) {
  .center .bottle-bg {
    width: 220px;
    height: 340px;
  }
}

.center .bottle-img {
  position: absolute;
  top: 36%;
  left: 36px;
  width: 28vw;
 
}
@media screen and (max-width: 476px) {
  .center .bottle-img{
    display: none;
  }
  
}
@media screen and (max-width: 969px){
  .center .bottle-img{
    display: none;
  }
}



.swiper-pagination {
  position: absolute;
  right: 30px;
  left: auto;
  top: 26px;
  width: auto;
  bottom: auto;
  z-index: 2;
  font-size: 14px;
  font-family: var(--body-font);
  font-weight: 500;
  color: black
}

.button-wrapper {
  position: absolute;
  right: 30px;
  bottom: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 930px) {
  .button-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    padding: 0 60px;
  }
}
@media screen and (max-width: 575px) {
  .button-wrapper {
    padding: 0 20px;
  }
}
.button-wrapper svg {
  width: 28px;
  
}
.button-wrapper .swiper-button {
  border: 1px solid var(--body-color);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, var(--body-color) 40%, transparent 0%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
  cursor: pointer;
  color: black
  
}
.button-wrapper .swiper-button + .swiper-button {
  margin-left: 16px;
}
@media screen and (max-width: 930px) {
  .button-wrapper .swiper-button + .swiper-button {
    margin-left: 0;
  }
}
.button-wrapper .swiper-button:hover {
  background-color: var(--body-color);
  background-position: left bottom;
}
.button-wrapper .swiper-button:hover svg {
  stroke-linecap: #000000;
}
.button-wrapper .swiper-prev-button {
  background: linear-gradient(to left, var(--body-color) 40%, transparent 0%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.3s ease-out;
}
.button-wrapper .swiper-prev-button svg {
  transform: rotate(-180deg);
}
.button-wrapper .swiper-prev-button:hover {
  background-position: right bottom;
}
.button-wrapper .swiper-next-button {
  background: linear-gradient(to left, var(--body-color) 40%, transparent 0%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.3s ease-out;
}
.button-wrapper .swiper-next-button svg {
  transform: rotate(-180deg);
}
.button-wrapper .swiper-next-button:hover {
  background-position: right bottom;
}

.swiper-slide {
  opacity: 0 !important;
  transition: 0.4s;
}
.swiper-slide-active {
  opacity: 1 !important;
}

.swiper-slide .main-wrapper > *,
.swiper-slide .main-content > * {
  transform: translateY(-30px);
  opacity: 0;
  transition-duration: 0.8s;
}

.swiper-slide-active .main-wrapper > *,
.swiper-slide-active .main-content > * {
  transform: none;
  opacity: 1;
}

.swiper-slide .bottle-bg {
  transition-duration: 0.6s;
  opacity: 0;
  object-position: 60%;
}

.swiper-slide-active .bottle-bg {
  opacity: 1;
  transform: none;
  object-position: 50%;
}

.swiper-slide .bottle-img {
  transition-duration: 0.8s;
  transform: scale(1.2);
  opacity: 0;
}

.swiper-slide-active .bottle-img {
  opacity: 1;
  transform: scale(1.6);
}

[data-sld="1"] .container,
[data-sld="1"] .header {
  background-color: var(--savanna-bg);
}

[data-sld="2"] .container,
[data-sld="2"] .header {
  background-color: var(--glacier-bg);
}

[data-sld="3"] .container,
[data-sld="3"] .header {
  background-color: var(--coral-bg);
}